.doc-container {
  padding-top: 153px;
  padding-left: 346px;
  padding-right: 64px;
  padding-bottom: 50px;

  pre {
    font-size: 16px;
  }

  .doc-table {
    width: 100%;
    font-size: 16px;

    &.icons tr th,
    &.icons tr td {
      text-align: center;
    }

    .icon-white {
      padding: 8px;
      background-color: black;

      p {
        color: white;
      }
    }
  }

  .doc-table,
  .doc-table th,
  .doc-table td {
    padding: 24px;
    border: 1px solid #e2ebf8;
    border-collapse: collapse;
    text-align: left;
  }

  .doc-table-bg-color {
    background-color: #f5fafe;
  }

  .custom-tooltip {
    background-color: #387085 !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
    color: white !important;
    opacity: 1 !important;
    min-width: 167px !important;

    &:after {
      border-bottom-color: #387085 !important;
    }
  }
}
