.doc-sidebar {
  position: fixed;
  margin-top: 32px;
  padding-left: 41px;
  padding-right: 32px;
  width: 314px;
  height: 100vh;
  max-height: calc(100vh - 32px);
  border-right: 1px solid #e2ebf8;
  overflow-y: auto;
  background-color: white;
  z-index: 1;

  .space {
    position: fixed;
    top: 0;
    left: 0;
    width: 314px;
    height: 32px;
    background-color: #ffffff;
  }

  a {
    text-decoration: none;
    outline: none;
  }

  ul {
    padding: 0;
    list-style: none;
  }

  .menu-header {
    padding-bottom: 32px;
    border-bottom: 1px solid #e2ebf8;

    a {
      display: inline-flex;
      align-items: flex-end;
    }

    h1 {
      margin-bottom: 1rem;
    }

    img {
      margin-bottom: 8px;
      height: 22px;
      max-width: 100%;
    }

    span {
      font-size: 15px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #d71313;
    }
  }

  .menu-links {
    padding-top: 32px;
    margin-bottom: 32px;

    a {
      color: black;
    }

    .link-item {
      &.active .single-link {
        text-decoration: underline;
      }

      &.active:not(:first-child) {
        margin-top: 32px;
      }

      &.active {
        margin-bottom: 32px;
      }

      &.active .sidenav {
        display: block;
      }

      .link {
        display: block;
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }

      .sidenav {
        display: none;

        li.active a {
          margin-left: -9px;
          padding: 4px 9px;
          background: #000000;
          color: #ffffff;
        }

        li {
          margin-bottom: 8px;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}